<template lang="pug">
div
  v-slide-x-reverse-transition
    ControlButtons(v-if="showActions" :controlButtons="controlButtons" :item="item")
  ControlButtons(:controlButtons="mainControlButtons")
</template>

<script>
export default {
  name: 'ActionButtons',
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  props: {
    isHidden: { type: Boolean, default: () => false },
    isShowEdit: { type: Boolean, default: () => true },
    isShowDelete: { type: Boolean, default: () => true },
    item: { type: Object || File, default: () => ({}) }
  },
  data () {
    return {
      showActions: false,
      controlButtons: [
        {
          id: 1,
          name: 'mdi-trash-can-outline',
          action: (item) => {
            this.$emit('deleteFile', item)
          },
          color: 'white',
          tooltip: 'tooltip.delete',
          checkAccess: () => this.isShowDelete,
          buttonOptions: { xSmall: true, fab: true, icon: false, color: 'primary', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: (item) => this.$emit('editFile', item),
          color: 'white',
          tooltip: 'tooltip.edit',
          checkAccess: () => this.isShowEdit,
          buttonOptions: { xSmall: true, fab: true, icon: false, color: 'primary', outlined: false }
        }
      ],
      mainControlButtons: [
        {
          id: 1,
          name: 'mdi-dots-vertical',
          action: () => (this.showActions = !this.showActions),
          color: 'white',
          checkAccess: () => !this.isHidden,
          buttonOptions: { xSmall: true, fab: true, icon: false, color: 'primary', outlined: false }
        }
      ]
    }
  }
}
</script>
